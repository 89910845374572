import React, { useState, useEffect, useContext } from 'react';
import './wise-tips.scss';
import WiseTipCard from '../common/WiseTipCard';
import LeftChevronIcon from '../../images/icons/arrow-left.png';
import { Link } from "gatsby";
import PWMarkdown from '../common/PWMarkdown';
import { api } from "../../../utils";
import UserContext from '../../context/UserContext';

const GATSBY_CMS_SERVER = process.env.GATSBY_CMS_SERVER;

const WiseTipDetail = (props) => {
    const { data, location, isPreview=false } = props;
    const userData = useContext(UserContext);
    const user = userData && userData.user;
    const [bookmarks, setBookmarks] = useState([]);

    useEffect(() => {
        updatePostView();
    }, []);

    useEffect(() => {
        if (user) {
            fetchBookmarks();
        }
    }, [user]);

    const updatePostView = () => {
        if (isPreview || !data || data.status !== 'publish' || !data.databaseId) {
            return;
        }
        fetch(`${GATSBY_CMS_SERVER}/wp-json/post-views-counter/view-post`, {
            "headers": {
                'Content-Type': 'application/json'
            },
            "body": JSON.stringify({id: data.databaseId}),
            "method": "POST",
        });
    }

    const fetchBookmarks = () => {
        api.get('/api/v1/user-bookmark/list/wise-tip')
        .then(resp => {
            if (resp && resp.success && resp.data && resp.data.length) {
                let bookmarks = [];
                resp.data.forEach(i => bookmarks.push(i.slug));
                setBookmarks(bookmarks);
            } else {
                setBookmarks([]);
            }
        });
    }

    if (!data || data.status !== 'publish') {
        return null;
    }

    return (
        <div className="w3-content pw-wt-detail-container">
            <div className="w3-row">
                <Link to={`/wise-tips/?type=${data.acf_wise_tip.type}`} className="pw-wt-detail-back">
                    <img src={LeftChevronIcon} className="pw-wt-detail-back-icon" />
                    <div>
                        Back to {`${(data.acf_wise_tip.type === 'coreFinding') ? 'Core Findings' : 'WiseTips'}`}
                    </div>
                </Link>
            </div>
            <div className="w3-row">
                <div className="w3-col m4">
                    <div className="w3-row pw-wt-detail-left">
                        <WiseTipCard
                            data={data}
                            location={location}
                            hideLink
                            size="m12"
                            inDetailPage={true}
                            user={user}
                            bookmarks={bookmarks}
                            fetchBookmarks={fetchBookmarks}
                        />
                    </div>
                </div>

                <div className="w3-col m8">
                    <div className="pw-wt-detail-right">
                        <div className="w3-row pw-wt-content">
                            <div className="w3-col s12">
                                {(data.acf_wise_tip.type === 'wiseTip') ?
                                    <div className="pw-wt-content-title">
                                        WHY IT MATTERS
                                    </div>
                                    : null
                                }
                                <div className="pw-wt-content-desc">
                                    {(data.acf_wise_tip && data.acf_wise_tip.description) && <PWMarkdown data={data.acf_wise_tip.description}/> }
                                </div>
                            </div>

                            {(data.acf_wise_tip && data.acf_wise_tip.enableSurvey === 'yes') && (
                                <div className="w3-col w3-padding-16">
                                    <Link to={`/wise-tip/${data.slug}/survey`} className="pw-wp-survey-link">
                                        Take our survey to win!
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WiseTipDetail