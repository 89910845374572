import React from 'react';
import Layout from '../../components/layout';
import '../../components/WiseTips/wise-tips.scss';
import WiseTipDetail from '../../components/WiseTips/WiseTipDetail';
import SEO from '../../components/seo';
import { getPlainText } from '../../../utils';
import { slice } from 'lodash';

const WiseTipDetailTemplate = ({ location, isPreview=false, pageContext: { data } }) => {

    const words = getPlainText(data.acf_wise_tip.tip).split(" ");
    const desc = slice(words, 0, 20);

    return (
        <div className="pw-wt-detail-page">
            <div className="w3-display-container pw-wt-detail-texture">
                <img loading="lazy" src="https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png" className="pw-wt-top-right-texture w3-hide-small w3-hide-medium" />
                <Layout>
                    <SEO
                        title={data.title}
                        description={words.length > 20 ? `${desc.join(' ')}...` : `${desc.join(' ')}`}
                        path={location.pathname}
                    />
                    <WiseTipDetail data={data} location={location} isPreview={isPreview} />
                </Layout>
            </div>
        </div>
    )
}

export default WiseTipDetailTemplate