import React from 'react';
import Layout from '../../components/layout';
import '../../components/WiseTips/wise-tips.scss';
import PWMarkdown from '../../components/common/PWMarkdown';

const QuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png';

const DefinitionTemplate = ({ pageContext: { data } }) => {
    if (!data || !data.acf_definition) {
        return null;
    }

    return (
        <div className="pw-wt-detail-page">
            <div className="w3-display-container pw-wt-detail-texture">
                <img loading="lazy" src={QuotesGroupIcon} className="pw-wt-top-right-texture w3-hide-small w3-hide-medium" />
                <Layout>
                    <div className="pw-markdown" style={{ minHeight: '80vH'}}>
                        <div className="pw-pop-up-container">
                            <div className="pw-pop-up-modal w3-modal w3-show" onClick={evt => evt.stopPropagation()}>
                                <div className="pw-close-container"><button className="pw-close" /></div>
                                <div className="w3-modal-content">
                                    <PWMarkdown data={data.acf_definition.description} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        </div>
    )
}

export default DefinitionTemplate