import React, { useEffect, useState } from "react";
import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import { getPostQuery, getReadDurationMap } from '../../utils';
import WiseTipDetail from '../templates/WiseTipDetail/index';
import SuperHonestlyDetail from '../templates/SuperHonestlyDetail/index';
import DefinitionTemplate from '../templates/DefinitionTemplate/index';
import SuperHonestlyListingPage from '../templates/SuperHonestlyListing/index';
import HomePage from '../pages/index';
import PrivacyPage from '../pages/privacy-policy';
import TermsOfUsePage from '../pages/terms-of-use';
import WiseJourneyPage from '../templates/WiseJourneyPage/index';
import WiseSelf from '../pages/wise-self';
import WiseSpeak from '../pages/wise-speak';

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
        fetch,
        uri: `${process.env.GATSBY_CMS_SERVER}/graphql`,
    })
});


const Preview = ({ location }) => {
    const urlParams = new URLSearchParams(
        location && location.search && location.search
    );

    const postId = urlParams.get('id');
    const postType = urlParams.get('type');
    const slug = urlParams.get('slug');

    const [data, setData] = useState(null); 

    useEffect(() => {
        if (!postId || !postType || !slug) {
            return;
        }
        fetchData(postId, postType, slug);
    }, []);

    const fetchData = async (postId, postType, slug) => {
        try {
            await client.query({
                query: gql`${getPostQuery(postId, postType, slug)}`
            }).then(result => {
                if (postType === 'wise-tip') {
                    setData({
                        type: postType,
                        content: (result.data && result.data.wiseTip) ? result.data.wiseTip : null
                    })
                } else if (postType === 'super-honestly') {
                    setData({
                        type: postType,
                        content: (result.data && result.data.superHonestly) ? result.data.superHonestly : null
                    })
                } else if (postType === 'wise-self') {
                    setData({
                        type: postType,
                        content: (result.data && result.data) ? result.data : null
                    })
                } else if (postType === 'wise-speak') {
                    setData({
                        type: postType,
                        content: (result.data && result.data) ? result.data : null
                    })
                } else if (postType === 'definition') {
                    setData({
                        type: postType,
                        content: (result.data && result.data.definition) ? result.data.definition : null
                    })
                } else if (postType === 'page') {
                    setData({
                        type: `page_${slug}`,
                        content: (result.data && result.data) ? result.data : null,
                    })
                }
            });
        } catch (ex) {
            console.log("error", ex);
        }
    }

    const renderContent = (data) => {
        switch(data.type) {
            case "wise-tip" :
                return (
                    <WiseTipDetail pageContext={{
                            data: data.content
                        }}
                        location
                        isPreview={true}
                    />
                )
            case "super-honestly" :
                return (
                    <SuperHonestlyDetail pageContext={{
                            data: data.content
                        }}
                        location
                        isPreview={true}
                    />
                )
            case "wise-self" :
                return (
                    <WiseSelf data={{
                            cms: data.content
                        }}
                        location
                    />
                )
            case "wise-speak" :
                return (
                    <WiseSpeak data={{
                            cms: data.content
                        }}
                        location
                    />
                )
            case "definition" :
                return (
                    <DefinitionTemplate pageContext={{
                            data: data.content
                        }}
                    />
                )
            case "page_home-page" :
                return (
                    <HomePage data={{
                            cms: data.content
                        }}
                        location
                    />
                )
            case "page_privacy-policy" :
                return (
                    <PrivacyPage data={{
                            cms: data.content
                        }}
                        location
                    />
                )
            case "page_terms-and-conditions" :
                return (
                    <TermsOfUsePage data={{
                            cms: data.content
                        }}
                        location
                    />
                )
            case "page_super-honestly" :
                const superHonestlyPage = (data.content.page) ? data.content.page : {};
                const superHonestlyList = (data.content.superHonestlys &&
                    data.content.superHonestlys.nodes) ? data.content.superHonestlys.nodes : [];
                const shReadDurationMap = getReadDurationMap(superHonestlyList);
                return (
                    <SuperHonestlyListingPage pageContext={{
                            superHonestlyPage,
                            superHonestlyList,
                            shReadDurationMap
                        }}
                        location
                    />
                )
            case "page_wise-journey" :
                const wjPage = (data.content.page) ? data.content.page : {};
                return (
                    <WiseJourneyPage pageContext={{
                            wjPage
                        }}
                        location
                    />
                )
            default :
                return (
                    <div className="w3-padding-64">
                        <h3 className="w3-center">Failed to fetch the data.</h3>
                    </div>
                )
        }
    }

    if (!data) {
        return (
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <div className="pw-loader" />
            </div>
        )
    }

    return renderContent(data);
}

export default Preview;