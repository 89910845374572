import React from "react"
import PWMarkdown from '../components/common/PWMarkdown';
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import "./pages.scss"
import BackArrow from "../images/icons/arrow-left.png"
import SEO from "../components/seo";

const Logo = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png";
  
const TermsOfUse = ({ data, location }) => {
    const values = data.cms.page.page_content;
    return (
        <div className="terms-bg">
            <Layout>
                <SEO
                  title="Privacy Policy"
                  path={location.pathname}
                />
                <img loading="lazy" className="super-ho-logo w3-hide-small" src={Logo} />
                <div className="w3-row" style={{position: "relative"}}>
                    <Link to="/" className="pw-wt-detail-back-to" style={{position: "absolute"}}>
                        <img src={BackArrow} className="pw-wt-detail-back-icon" />
                        <div>
                            Back to Homepage
                        </div>
                    </Link>
                </div>
                <div className="w3-row w3-center w3-hide-medium w3-hide-large terms-header">PRIVACY</div>
                <div className="w3-content terms-of-use">
                    <div className="w3-row w3-center w3-hide-small terms-header">PRIVACY</div>
                    <PWMarkdown className="markdown" data={values.content} />
                </div>
            </Layout>
        </div> 
    )
}

export const query = graphql`
  {
    cms {
      page(id: "privacy-policy", idType: URI) {
        page_content {
          content
        }
      }
    }
  }
`

export default TermsOfUse;